import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Product = ({ product }) => {
	const { t } = useTranslation();

	// Always call useState first
	const [imageLoaded, setImageLoaded] = useState(false);

	if (!product) {
		console.error('Product is undefined or null:', product);
		return (
			<div className='text-red-500'>Error: Product data is not available.</div>
		);
	}

	const { id, image, category, name, unit, price } = product || {};

	const handleImageLoad = () => {
		// When the image has finished loading, set the imageLoaded state to true
		setImageLoaded(true);
	};

	return (
		<div>
			<div className='border border-[#e4e4e4] mb-4 relative overflow-hidden group transition rounded-lg'>
				<div className='border shadow-lg rounded-lg hover:scale-105 duration-300'>
					<Link to={`/product/${id}`}>
						<div
							className={`w-full h-[200px] rounded-t-lg overflow-hidden transition-opacity ${
								imageLoaded ? 'opacity-100' : 'opacity-0'
							}`}
						>
							<img
								src={image}
								alt={name || 'Product Image'}
								className='w-full h-full object-cover'
								loading='lazy'
								onLoad={handleImageLoad}
							/>
						</div>
					</Link>
				</div>
			</div>
			<div className='flex'>
				<p className='text-base capitalize text-gray-500'>{t(category)}</p>
			</div>

			<div>
				<div className='flex md:flex-row lg:flex-row xl:flex-row flex-col justify-between my-2'>
					<p className='text-lg capitalize font-bold bg-blue-600 text-white p-2 rounded-md'>
						{price !== undefined && `$${price}`}
						<span className='text-lg font-semibold text-white'>/{unit}</span>
					</p>
				</div>

				<Link to={`/product/${id}`}>
					<h2 className='font-semibold mb-1'>{name}</h2>
				</Link>
			</div>
		</div>
	);
};

export default Product;
