import { createContext, useState, useEffect } from 'react';

export const ProductContext = createContext();

const ProductProvider = ({ children }) => {
	const [products, setProducts] = useState([]);
	const [error, setError] = useState(null); // Error state for product fetching

	useEffect(() => {
		const fetchProducts = async () => {
			try {
				const response = await fetch(
					'https://oriental-trading-ca-server.vercel.app/products'
					// '/products'
				);
				if (!response.ok) {
					throw new Error('Failed to fetch products');
				}
				const data = await response.json();
				setProducts(data.data);
			} catch (error) {
				console.error('Error fetching products:', error);
				setError('Failed to load products. Please try again later.'); // Set error message
			}
		};
		fetchProducts();
	}, []);

	return (
		<ProductContext.Provider value={{ products, error }}>
			{children}
		</ProductContext.Provider>
	);
};

export default ProductProvider;
