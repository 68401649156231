import { useTranslation } from 'react-i18next';

const Special = ({ offers }) => {
	const { t } = useTranslation();

	return (
		<div className='container mx-auto'>
			{offers.length > 0 ? (
				// Render special offers if there are any
				// > if there is
				// === there is none
				offers.map((offer) => (
					<div
						key={offer.id}
						className='my-5 rounded-lg overflow-hidden border border-blue-400 shadow-md bg-white'
					>
						<div className='p-4 bg-blue-400 text-white'>
							<h2 className='text-center font-bold text-lg md:text-xl uppercase'>
								{/* {t('march')}-{t('april')}  */}
								2025
							</h2>
						</div>
						<ul className='divide-y divide-blue-400'>
							{offer.items.map((item, index) => (
								<li key={index} className='flex items-center py-3 px-4'>
									<img
										className='w-[100px] h-[100px] rounded-full mr-3'
										src={item.image}
										alt={item.name}
									/>
									<div className='flex-1'>
										<h3 className='font-semibold'>{item.name}</h3>
										<p className='text-gray-600'>{item.price}</p>
									</div>
								</li>
							))}
						</ul>
					</div>
				))
			) : (
				// Render a message if there are no special offers
				<div className='text-center my-5 rounded-lg overflow-hidden border border-blue-400 shadow-md bg-white'>
					<div className='p-4 bg-blue-400 text-white'>
						<p className='font-semibold text-lg'>{t('noSpecial')}</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default Special;
